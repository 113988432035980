<template>
  <div class="w-full mt-32 flex justify-center items-center">
    <h1
      class="
        bg-white
        p-5
        rounded-lg
        shadow-md shadow-blue-600
        hover:bg-gray-50
        text-3xl
        ring-2 ring-blue-600
      "
    >
      Registration has been closed
    </h1>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>